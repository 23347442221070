
import Grid from "@material-ui/core/Grid"
import Aos from "aos"
import React, {useEffect } from 'react'
import "aos/dist/aos.css";
import { Image } from 'semantic-ui-react'
import ResumeSection from "./ResumeSection"

export default function AboutSection() {
 
    useEffect(() => {
        Aos.init({duration: 500})
    }, [])

    return (  
        <>
        <Grid container>
            <Grid item sm={6}>
                <div id="about" style={{
                    paddingTop: "68px",
                    
                }}>
               
                <img className="mypic mb-4" src="./harryji.png" alt="mypic" data-aos="fade-in"/>
                </div>    
            </Grid>    
            <Grid item sm={6}>    
                <p className="title text" >ABOUT ME</p>
                <p className="description m-4" >Certified IT professional with 10+ years keep upgrading skills and experience at <strong>Full-stack Development</strong>.I offered one-stop creative solutions that included UI/UX Design, Development, Promotion, Hosting, and E-commerce services, building different Web and Mobile applications, including Andriod, IOS, Desktop, and Raspberry Pi Apps. In addition, I acted as a system engineer and database administrator. </p>
                <p className="description m-4" >I also like music, sports, reading, cooking and travel. </p>
           
            </Grid>
        
        </Grid>

        </>
    )
}
