import Grid from "@material-ui/core/Grid"
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { Button, Header, Image, Modal,Icon } from 'semantic-ui-react'
import Aos from "aos"
import React, {useEffect } from 'react'
import "aos/dist/aos.css";


const images = [
    {
      src: "https://user-images.githubusercontent.com/21187699/145527320-6a9cd522-4945-41ad-968b-8e6fa62e5349.gif",
      title: 'Auto Onboarding-System',
      width: '30%',
      description:`Only one final project provides solutions to help CodeCore College improve its current system. I
      used more than ten cutting-edge technologies that the school didn't teach but self-learned during
      my study in the school.\
      \
      Part 1: Job Assistance: The job map gets data from job search engines like LinkedIn and
summarizes the skill requirements, and provides API for jobs seekers.\   
Part 2: ESL Support: AI live chat assistance with bilingual voice support, which allows users to
access customer service in their native language.\
Part 3: Auto Coding System: A cross-platform desktop app that auto-controls GitHub and VSS
to write code, including machine learning and auto debug. \   
      `,
      technology:["Ruby on Rails", "ReactJS", "Bootstrap","Express", "PosgreSQL","Stripe","Web GL","Web RTC","Electron"],
      github:"https://github.com/harryji168/Final_Project_Auto-Onborading-System",
      app:"https://www.youtube.com/watch?v=vSqGMHKceYw"
    },
    {
      src: "projects/group_project.png",
      title: 'School Management',
      width: '30%',
      description:"This project was an only one successful group project using remote work in CodeCore College\
      after three previous projects failed. Used diagrams for ERD, Trello for Project management, and\
      Figma for UX\/UI Design. Finally, our college built a significant belt award for finishing this great\
      project for the first time.",
      technology:["Ruby on Rails", "HTML","CSS", "Bootstrap","jQuery","Calendar","Chart","CRUD","PosgreSQL"],
      github:"https://github.com/harryji168/Group_Project_School_Management",
      app:"https://www.youtube.com/watch?v=9-WP7CnYgEM"
    },
    {
      src: "https://user-images.githubusercontent.com/21187699/142677367-a279cb4e-a9bc-4715-b564-85a88e855f53.gif",
      title: 'Online Auctions',
      width: '30%',
      description:`I have done all two systems requirements within one day, including all three bonus stretch parts.
      In addition, extra add documentation, a customized logo, and two creative promotion movies.`,
      technology:["React","API","Ruby on Rails", "Bootstrap","HTML","CSS", "PosgreSQL","UI/UX Design"],
      github:"https://github.com/harryji168/Final_Exam_Online_Auctions",
      app:""
    },
    {
      src: "https://user-images.githubusercontent.com/21187699/138769227-90f5a38b-2328-42de-9622-38428b063c19.png",
      title: 'IDEA FACTORY',
      width: '30%',
      description:`Created on Ruby on Rails, this project uses a PostgreSQL database. Blog on Rails is a discussion or informational website that allows the
      content creator to publish unique ideas and create, delete, and edit the views. The ability to create and delete comments is a function for
      each post. I completed it within one day.`,
      technology:["React","SemanticUI","MaterialUI","Bootstrap"],
      github:"https://github.com/harryji168/Idea-factory",
      app:""
    },
    {
      src: "https://user-images.githubusercontent.com/21187699/137592184-6ffa1ddf-51d0-4ba7-88fb-eacf7118d4f6.png",
      title: 'Clucks Trending',
      width: '30%',
      description:`A simple Twitter clone using Express. I built an Express app, created a sign-in page, and a Postgres database for Cluckr. I made Clucks,
      and an index page to list and show all entries and added a navigation bar. A User-Friendly Date was added along with a trending tags
      section that appears in larger text on mobile platforms. I completed it with strength within one day.`,
      technology:["NodeJS", "Express","Bootstrap","Knex.js", "PosgreSQL","Faker","CRUD"],
      github:"https://github.com/harryji168/Clucks_Trending",
      app:""
    },
    {
      src: "projects/hangman.gif",
      title: 'HANGMAN',
      width: '30%',
      description:`This classic game was built using Document Object Model (DOM) manipulation through JavaScript, HTML and CSS files. Users have six
      tries to guess the correct word. For every incorrect guess, the hangman image changes and the incorrect letter is blocked from use.`,
      technology:["jQuery","Bootstrap","HTML","CSS","Javascript"],
      github:"https://github.com/harryji168/hangman",
      app:"https://harryji168.github.io/hangman/"
    },
  ];

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    //   minWidth: ,
      width: '100%',
    },
    image: {
      position: 'relative',
      height: 350,
      [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        // maxWidth: "100%",
        height: 150,
        margin:"2%"
        
      },
      
      marginLeft: 20,
      marginRight: 10,

      '&:hover, &$focusVisible': {
        zIndex: 1,
        '& $imageBackdrop': {
          opacity: 0.15,
        },
        '& $imageMarked': {
          opacity: 0,
        },
        '& $imageTitle': {
          background:"black",
          opacity: "0.7",
          // color: "whit",
          border: '4px solid white',
        },
      },
    },
    focusVisible: {},
    imageButton: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.white,
     
    },
    imageSrc: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundRepeat: "no-repeat",
      backgroundSize: '100%',
      backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
      position: 'relative',
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
      height: 3,
      width: 18,
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      bottom: -2,
      left: 'calc(50% - 9px)',
      transition: theme.transitions.create('opacity'),
    },
  }));

  function exampleReducer(state, action) {
    console.log(action.id)
    switch (action.type) {
      case 'OPEN_MODAL':
        return { open: true, dimmer: action.dimmer, id: action.id}
      case 'CLOSE_MODAL':
        return { open: false }
      default:
        throw new Error()
    }
  }
  
export default function ProjectsSection() {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
    id: undefined
  })
  useEffect(() => {
    Aos.init({duration: 500})
}, [])
  const { open, dimmer,id } = state
    const classes = useStyles();
    return (

        <>
        <div style={{
          backgroundColor:"black",
          
          
        }}>
      <div id="projects" style={{
        margin:"2%",
        paddingTop: "60px"
      }} >
        <Grid container 
        justify="center"
        alignItems="center"
        >
            <Grid item>
              <p textAlign="center" className="resumetext" style={{
                
                color:"white",
                
              }}>PROJECTS</p>
              </Grid>
            
                <div className={classes.root}>
             
               {images.map((image,i) => (
                    
                    <ButtonBase
                    focusRipple
                    key={i}
                    className={classes.image}
                    focusVisibleClassName={classes.focusVisible}
                    style={{
                        width: image.width,
                    }}
                    data-aos="flip-up"
                    >
                    <span
                        className={classes.imageSrc}
                        style={{
                        backgroundImage: `url(${image.src})`
                        }}
                    />
                    <span className={classes.imageBackdrop} />
                    <span className={classes.imageButton}>
                                <Typography
                                key={image.id}
                                // imageInfo={image}
                                component="span"
                                variant="subtitle1"
                                color="inherit"
                                className={classes.imageTitle}
                                onClick={() => dispatch({ type: 'OPEN_MODAL', id:i, dimmer:"blurring"}
                                )}
                                >
                                {image.title}
                                <span className={classes.imageMarked} />
                                </Typography>


                    </span>

                    <Modal
                      closeIcon={{ style: { top: '0.8rem', right: '1rem' }, color: 'black', name: 'close'}}
                      dimmer={dimmer}
                      open={open}
                      onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
                      id={id}
                      size="medium"
                      
                     
                    >      
                      {id !== undefined ? 
                      <React.Fragment >
                      <Modal.Header className="mb-4 text-center"  data-aos="flip-up">{images[id].title} </Modal.Header>
                      <Modal.Content 
                      className="mt-2"image>
                        <Image size='xlarge' src={images[id].src} />
                      </Modal.Content>
                      <Modal.Content>
                      <Modal.Description>
                        <Header></Header>
                        <p>{images[id].description}
                        </p>
                        <br/>


                        <p className="techProject text-center">Tech Stack: {images[id].technology.join(", ")}</p>
                      
                      </Modal.Description>

                    </Modal.Content>
                    
                       
                      <Modal.Actions className="text-center">
                      <a href={images[id].github} target="_blank" rel="noreferrer">
                        <Button icon labelPosition='left' color="black">
                          <Icon name="github"/>
                          View Source
                          
                        </Button>
                        </a>
                        {images[id].app.length>0 ? 
                        <a href={images[id].app} target="_blank" rel="noreferrer">
                        <Button icon labelPosition='left' color="black">
                        <Icon name="globe"/>
                          View App
                        </Button>
                        </a>
                        :""}
                      </Modal.Actions>
                      </React.Fragment>
                        :""}
                    </Modal>
                    </ButtonBase>
                  
             
       
      ))
      }
      </div>
        </Grid>
        </div>
        </div>
        </>
    )
    
}
