import React from 'react'
import 'fontsource-roboto';
import Typing from 'react-typing-animation';
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

let config = {
    num: [10, 18],
    rps: 3, 
    life: [1.5, 3],
    // body: "./img/icon.png", // Whether to render pictures
    // rotate: [0, 20],
    alpha: [0.6, 0],
    scale: [1, 0.1],
    position: "center", // all or center or {x:1,y:1,width:100,height:100}
    color: ["random", "#ff0000"], 
    random: 15,  // or null,
   
   
  };


export default function IntroSection() {

    return (
        
        <section id="intro" className="intro text-center">               
            <div className="overlay col-sm-12">                
                <div className="intro-content">
                <ParticlesBg type="custom" config={config} bg={true}  />
                <Fade top>
                    <h1 className="name">Hi, I'm Harry Ji </h1>
                </Fade>
                <Typing >
                <p class="subtitle">10+ years professional certified full-stack developer.</p>
                </Typing>
                <br/>
                </div>


            </div>
            
            <div>


            </div>

           
        </section>
      
    )
}
